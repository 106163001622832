import "bootstrap";
import "../scss/style.scss";
import "../fontawesome/css/all.min.css";

import $ from "jquery";

// Example jQuery usage
jQuery(function () {
  $('button[data-bs-toggle="collapse"]').on("click", function () {
    var chevron = $(this).find(".fa-chevron-down"); // Find the chevron icon

    // Toggle the 'expanded' and 'collapsed' classes
    chevron.toggleClass("expanded collapsed");
  });

  var navbar = $("header#header"); // Select your navbar
  var scrollTrigger = 50; // Adjust this value to change when the background appears

  $(window).scroll(function () {
    if ($(this).scrollTop() > scrollTrigger) {
      navbar.addClass("header--scrolled"); // Add a class to change background
    } else {
      navbar.removeClass("header--scrolled"); // Remove the class
    }
  });

  // Listen for changes on the category checkboxes
  $(".faq-filter__chip-checkbox").on("change", function () {
    // Collect all selected categories
    var selectedCategories = [];
    $(".faq-filter__chip-checkbox:checked").each(function () {
      selectedCategories.push($(this).val());
    });

    // Update the URL without reloading the page
    var newUrl =
      window.location.protocol +
      "//" +
      window.location.host +
      window.location.pathname;
    if (selectedCategories.length > 0) {
      newUrl += "?category=" + selectedCategories.join(",");
    }

    // Use the browser's History API to update the URL
    window.history.pushState({ path: newUrl }, "", newUrl);

    // AJAX request for filtering FAQs
    $.ajax({
      url: "/wp-admin/admin-ajax.php", // URL for AJAX
      method: "GET",
      data: {
        action: "filter_faq_categories", // Action name declared in PHP
        category: selectedCategories.join(","), // Send selected categories
      },
      beforeSend: function () {
        // Show a loading message or indicator while waiting
        $("#faqAccordion").html("<p>Loading FAQs...</p>");
      },
      success: function (response) {
        // Replace the FAQ accordion content with the filtered FAQs
        $("#faqAccordion").html(response);
      },
      error: function () {
        // Display an error message if the request fails
        $("#faqAccordion").html(
          "<p>Something went wrong. Please try again.</p>"
        );
      },
    });
  });

  // Handle back/forward browser navigation with filtered FAQs
  window.onpopstate = function (event) {
    location.reload(); // Reload page to apply the URL parameters
  };
});
